var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Overlay',{attrs:{"overlay":_vm.overlay}}),_c('v-card',{staticClass:"elevation-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6"}},[_c('v-toolbar',{staticClass:"elevation-0"},[_c('div',{staticClass:"PrimaryFontColor heading1"},[_vm._v(" Transaction Logs ")])])],1),_c('v-toolbar',{staticClass:"elevation-0"},[_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6"}},[_c('v-text-field',{staticClass:"FontSize field_label_size field_height borderRadius maxWidthLarge mt-9 mr-3",attrs:{"dense":"","outlined":"","label":"Search Transaction Logs"},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{staticClass:"mt-1 mr-1",attrs:{"small":"","color":"primary"}},[_vm._v("mdi-magnify")])]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-card-text',{staticClass:"pt-0"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"search":_vm.search,"dense":"","headers":_vm.GetAllTransactionLogsListHeaders,"items":_vm.GetAllTransactionLogsList,"no-data-text":_vm.noDataText,"hide-default-footer":_vm.GetAllTransactionLogsList.length == 0,"footer-props":{
          'items-per-page-options': [100, 200, 300, 400, 500],
        },"items-per-page":100,"disable-sort":_vm.$vuetify.breakpoint.xs ? true : false},scopedSlots:_vm._u([{key:`item.sl_no`,fn:function({ item }){return [_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(_vm.GetIssuedCredentialsList.indexOf(item) + 1)+" ")])]}},{key:`item.credential_number`,fn:function({ item }){return [_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.credential_number)+" ")])]}},{key:`item.actions`,fn:function({ item }){return [(item.transaction_status != 'PENDING')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","color":"primary","icon":""},on:{"click":function($event){return _vm.downloadItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-download")])],1)]}}],null,true)},[_c('span',[_vm._v("Download")])]):_vm._e(),(item.transaction_status != 'PENDING')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","color":"primary","icon":""},on:{"click":function($event){return _vm.openViewBlock(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-forward")])],1)]}}],null,true)},[_c('span',{staticClass:"text-center"},[_vm._v("View On viewblock.io")])]):_vm._e()]}},{key:`item.transacted_on`,fn:function({ item }){return [_c('div',{staticClass:"FontSize",attrs:{"color":"primary","dark":""},domProps:{"textContent":_vm._s(
              `${new Date(item.transacted_on).toDateString() +
                ',' +
                ' ' +
                new Date(item.transacted_on).toLocaleString().split(',')[1]}`
            )}})]}},{key:`item.mined_at`,fn:function({ item }){return [_c('div',{staticClass:"FontSize",attrs:{"color":"primary","dark":""},domProps:{"textContent":_vm._s(
              item.mined_at != undefined
                ? `${new Date(item.mined_at).toDateString() +
                    ',' +
                    ' ' +
                    new Date(item.mined_at).toLocaleString().split(',')[1]}`
                : '-'
            )}})]}},{key:`item.fee_in_rupee`,fn:function({ item }){return [_c('div',{staticClass:"FontSize",domProps:{"textContent":_vm._s(
              item.fee_in_rupee == undefined
                ? '-'
                : `${item.fee_in_rupee.toString().slice(0, 4)} INR`
            )}})]}},{key:`item.type`,fn:function({ item }){return [_c('div',{staticClass:"FontSize",domProps:{"textContent":_vm._s(
              item.type == 'DOCUMENT'
                ? 'Document'
                : item.type == 'FREE_TEXT'
                ? 'Free Text'
                : item.type == 'TEMPLATE'
                ? 'Template'
                : '-'
            )}})]}},{key:`item.source`,fn:function({ item }){return [_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.source == undefined ? "-" : item.source)+" ")])]}},{key:`item.issued_using`,fn:function({ item }){return [_c('div',{staticClass:"FontSize"},[_vm._v(_vm._s(item.issued_using))])]}},{key:`item.transaction_status`,fn:function({ item }){return [_c('div',{staticClass:"FontSize font-weight-bold",class:item.transaction_status == 'ISSUED'
                ? 'GreenHeader'
                : item.transaction_status == 'DELAYED'
                ? 'RedHeader'
                : 'BlueHeader'},[_vm._v(" "+_vm._s(item.transaction_status)+" ")])]}}],null,true)})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }